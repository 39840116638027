import React from 'react';

const UserTerms = () => {
  return (
    <div className="w-full text-white items-center">
      <h2 className="text-white mt-10 text-xl text-poppins">Terms & Condition</h2>
      <p className="mb-4">The following terms and conditions (“Terms”) govern your acceptance and use of the Swipechain and its proposed programs and systems.  You hereby confirm you understand the Terms and hereby expressly agree to be bound by and comply with the Terms. Please review carefully and keep a copy of these Terms for your reference. If you have any questions about these Terms, please contact us prior to accepting or using our systems or programs.</p>
      <p className="mb-4">Swipechain reserves the right, subject to applicable law, to make changes to, modify, or add or remove portions of these Terms, our Privacy Policy and other incorporated terms and policies, in our sole discretion, at any time prior to the allocation recommendation. We encourage you to check our website frequently to see the current Terms in effect and any changes that may have been made to them prior to the using of our services . All changes made to the Terms are effective immediately when we post them. No one is authorized to modify these Terms with you or otherwise enter into an agreement with you that conflicts with these Terms, except by means of written agreement signed by an authorized agent of the Foundation, and any other purported modifications or alterations or conflicting terms will be null and void.</p>
      <p className="mb-4">Your Assumption of Risk Regarding Swipechain: </p>
      <p className="mb-4">You hereby expressly acknowledge and agree that;</p>
      <p className="mb-4">(i) You are accepting and using Swipechain, including in connection with the Swipechain Network</p>
      <p className="mb-4">(ii) You have an adequate understanding of the risks, usage and intricacies of cryptographic tokens and blockchain-based software on the Blockchain Network</p>
      <p className="mb-4">(iii) The current or future subsidiaries, affiliates, directors, officers, employees, agents, representatives, and any other person or entity with any involvement in the allocation or in developing software underlying the Swipechain Network, in each case, whether or not affiliated with us or any other such person or entity shall have no responsibility or liability for the loss, transfer, misappropriation, use or misuse of Swipechain, including as utilized in connection with the Network, any financial or physical harm or loss related thereto, and you hereby irrevocably waive any and all rights and claims with respect thereto</p>
      <p className="mb-4">
        (iv) You will comply with any and all applicable rules and laws regarding your use of Swipechain(vi) we do not endorse or recommend the use of any software or hardware wallet services and/or market exchanges (including any over-the-counter market) in connection with any storage
      </p>
      <p className="mb-4">You hereby expressly acknowledge and agree that Swipechain:</p>
      <p className="mb-4">(i) It is a decentralized network and the Subject Persons (A) are not responsible for the development or launch of the Network and (B) do not operate, maintain, support or otherwise authorize any activity related to the Tezos Network.</p>
      <p className="mb-4">(ii) is likely to experience scheduled and unscheduled downtime, interruptions, delays, hard forks, bugs,and cyberattacks.</p>
      <p className="mb-4"> (iii) May from time to time lack features or functionality and/or require maintenance, support or other adjustments.</p>
      <p className="mb-4">Intends to rest or rely on open-source software, and there is a risk that a person introduces weaknesses, bugs or other malicious code into the core infrastructural elements of Swipechain, causing the system to lose or erase all or a portion of the data used in connection with the Smart  Contract.</p>
      <p className="mb-4">In this respect, you understand and expressly acknowledge and agree  that none of the Subject Persons are responsible for, and have any obligation with respect to, any subsequent action by the Swipechain community or on the Blockchain Network that accepts, rejects, or otherwise affects this recommended allocation.</p>
      <p className="mb-4">Your Responsibility to Keep the Information Available to You Confidential</p>
      <p className="mb-4">You are solely responsible for maintaining the confidentiality of any private information pertaining to yourself and any account you create in connection therewith and you hereby agree to accept full responsibility for all activities that occur under any such account, whether conducted by or on behalf of you or without your authorization. You hereby acknowledge and agree that none of the Subject Persons will be liable for any loss or damage arising from your failure to comply with this Section. You hereby acknowledge and agree that any and all information you provide to any of the Subject Persons is governed by our Privacy Policy, and you hereby consent to all actions any of the Subject Persons take with respect to your information consistent with our Privacy Policy.</p>
      <p className="mb-4 font-bold">Disclaimers</p>
      <p className="mb-4">No party—including any of the Subject Persons—makes any representation or warranty about the suitability, reliability, availability, timeliness, security and accuracy of XTZ, including as utilized in connection with the blockchain Network. To the maximum extent permitted by applicable law, Swipechain and all related information, software and products are provided on “as is, where is” basis without representations, warranties or conditions of any kind, including with respect to merchantability, fitness for a particular purpose, title and non-infringement.</p>
      <p className="mb-4 font-bold">Limitation of Liability</p>
      <p className="mb-4">You hereby acknowledge and agree that, to the maximum extent permitted by applicable law, the disclaimers of liability contained herein apply to any and all risks of, use of, or inability to use (including in connection with any external network) all causes of action whatsoever of any kind in any jurisdiction, including, without limitation, actions for breach of warranty, breach of contract or tort (including negligence). To the maximum extent permitted by applicable law, in no event shall the Subject Persons be liable for any indirect, punitive, incidental, special, consequential damages or any damages whatsoever including, without limitation, damages for loss of use, data or profits, arising out of or in any way connected with the use or performance of Swipechain, or for any information, software and products obtained in connection with your acceptance and use whether based on contract, tort, negligence, strict liability or otherwise, even if any of the Subject Persons or any of their suppliers have been advised of the possibility of damages. Notwithstanding anything herein to the contrary, to the maximum extent permitted by applicable law, in no event shall any of the Subject Person’s maximum aggregate liability for any and all claims associated with or related to these Terms and/or the issuance of Acceptance and use of the recommended Swipechain </p>
      <p className="mb-4 font-bold">Dispute Resolution</p>
      <p className="mb-4">For any and all controversies, disputes, demands, claims, or causes of action between you and the Subject Persons (including the interpretation and scope of this Section) arising out of your acceptance or use of the Swipechain these Terms (as well as any related or prior agreement that you may have had with any of the Subject Persons), shall be subject to the exclusive jurisdiction.</p>
      <p className="mb-4">To the maximum extent permitted by applicable law, in connection with any claims arising out of your acceptance or use of our services (i) you will only be permitted to pursue claims against the Subject Persons on an individual basis, not as a plaintiff or class member in any class or representative action or proceeding, and (ii) you will only be permitted to seek relief (including monetary, injunctive, and declaratory relief) on an individual basis.</p>
      <p className="mb-4 font-bold">User Disputes</p>
      <p className="mb-4">You hereby acknowledge and agree that you are solely responsible for your interactions with Swipechain (including in connection with or related to the Blockchain Network) and none of the Subject Persons will have any liability or responsibility with respect thereto.</p>
      <p className="mb-4 font-bold">General</p>
      <p className="mb-4">The failure of any of the Subject Persons to exercise or enforce any right or provision of these Terms will not constitute a waiver of such right or provision. You may not assign these Terms without the prior written consent of the Foundation, but the Foundation may assign or transfer these Terms, in whole or in part, without restriction. The section titles in these Terms are for convenience only and have no legal or contractual effect. Notices to you may be made via either email or regular mail.</p>
      <p className="mb-4">You agree that no joint venture, partnership, employment, or agency relationship exists between you and any of the Subject Persons as a result of these Terms or your acceptance or use of Swipechain. The performance of these Terms by any of the Subject Persons is subject to existing laws and legal process, and nothing contained in this agreement is in derogation of any of the Subject Persons’ right to comply with governmental, court and law enforcement requests or requirements relating to your acceptance.</p>
      <p className="mb-4">Unless otherwise specified herein, these Terms constitute the entire agreement between you and the Subject Persons with respect to your acceptance and use of Swipechain and they all prior to contemporaneous communications and proposals, whether electronic, oral or written, between you and the Subject Persons with respect to your acceptance and use of Swipechain.</p>
      <p className="mb-4 font-bold">Contact Us</p>
      <p className="mb-4">We welcome your questions or comments regarding these Terms via email at support@swipechain.app</p>
    </div>
  )
}

export default UserTerms
