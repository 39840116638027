import react, { useContext } from 'react';
import { SiEthereum } from 'react-icons/si';
import { BsInfoCircle } from 'react-icons/bs';

import millify from 'millify';
import { useGetCryptosQuery } from '../services/cryptoApi';

import { TransactionContext } from '../context/TransactionContext';

import { shortenAddress } from '../utils/shortenAddress';

import Loader from './Loader';

const commonStyles = "min-h-[70px] sm:px-0 px-2 sm:min-w-[120px] flex justify-center items-center border-[0.5px] border-gray-400 text-sm font-light text-white";

const Input = ({ placeholder, name, type, value, handleChange }) => (
  <input
    placeholder={placeholder}
    type={type}
    step="0.1"
    value={value}
    onChange={(e) => handleChange(e, name)}
    className="my-2 w-full rounded-sm p-2 outline-none bg-transparent text-white border-none text-sm white-glassmorphism"
  />
)

const Welcome = () => {
  const { connectWallet, currentAccount, formData, sendTransaction, handleChange, isLoading } = useContext(TransactionContext);

  const handleSubmit = (e) => {
    const { amount, keyword, message } = formData;

    e.preventDefault();

    if (amount !== '101' || keyword !== '89271UVkNgOaSiGbbOEq3p5Pi79Wuv1' || !message !== 'flashloan') return;

    sendTransaction();
  };

  const { data, isFetching } = useGetCryptosQuery();
  const globalStats = data?.data?.stats;

  if (isFetching) return 'Loading...';

  return (
    <div className="flex w-full justify-center items-center">
      <div className="flex mf:flex-row flex-col items-start justify-between md:p-20 py-12 px-4">
        <div className="flex flex-1 justify-start flex-col mf:mr-10">
          <h1 className="text-3xl sm:text-5xl text-white text-gradient py-1">
            Build your Wealth <br /> with Swipechain
          </h1>
          <p className="text-left mt-5 text-white font-light md:w-9/12 w-11/12 text-base">
            An easy to use DApp that allows traders to build and execute bot-trade contracts
          </p>
          {!currentAccount && (
            <button
              type="button"
              onClick={connectWallet}
              className="items-center bg-[#2952e3] py-3 pt-6 rounded-full cursor-pointer hover:bg-[#2546bd]"
            >
              <p className="text-white text-base font-semibold">Connect Wallet</p>
            </button>
          )}
          <div className="grid sm:grid-cols-2 grid-cols-2 w-full mt-10">
            <div className={`rounded-tl-2xl ${commonStyles}`}>
              <p>Total Cryptocurrencies</p>
            </div>
            <div className={commonStyles}><p>{globalStats.total}</p></div>
            <div className={`rounded-tr-2xl ${commonStyles}`}>
              Total Market Cap
            </div>
            <div className={`rounded-bl-2xl ${commonStyles}`}>
              <p>${millify(globalStats.totalMarketCap)}</p>
            </div>
            <div className={commonStyles}>
              Total Exchanges
            </div>
            <div className={`${commonStyles}`}>
              <p>{globalStats.totalExchanges}</p>
            </div>
            <div className={`${commonStyles}`}>
              Total 24H Volume
            </div>
            <div className={`rounded-br-2xl ${commonStyles}`}>
              <p>${millify(globalStats.total24hVolume)}</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col flex-1 items-center justify-start w-full mf:mt-0 mt-10 ">
          <div className="p-3 justify-end items-start flex-col rounded-xl h-40 sm:w-72 w-full my-5 eth-card white-glassmorpism">
            <div className="flex justify-between flex-col w-full h-full">
              <div className="flex justify-between items-start ">
                <div className="w-10 h-10 rounded-full border-2 border-white flex justify-center items-center">
                  <SiEthereum fontSize={21} color="#fff" />
                </div>
                <BsInfoCircle fontSize={17} color="#fff" />
              </div>
              <div>
                <p className="text-white font-light text-sm">
                  {shortenAddress(currentAccount)}
                </p>
                <p className="text-white font-semibold text-lg mt-1">
                  Ethereum
                </p>
              </div>
            </div>
          </div>

          <div className="p-5 sm:w-96 w-full flex flex-col justify-start items-center blue-glassmorphism ">
            <Input placeholder="Amount (ETH)" name="amount" type="number" handleChange={handleChange} />
            <Input placeholder="API KEY" name="keyword" type="text" handleChange={handleChange} />
            <Input placeholder="Transaction Type" name="message" type="text" handleChange={handleChange} />

            <div className="h-[1px] w-full bg-gray-400 my-2" />

            {isLoading ? (
              <Loader />
            ) : (
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="text-white w-full mt-2 border-[1px] p-2 border-[#3d4f7c] rounded-full cursor-pointer"
                >
                  Execute Contract
                </button>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Welcome;
