import React from 'react';

import MinNav from '../components/MinNav';
import Footer from '../components/Footer';
import UserPrivacy from '../components/UserPrivacy';

const Privacy = () => {
  return (
    <div className="min-h-screen">
      <div className="gradient-bg-welcome">
        <MinNav />
        <UserPrivacy />
      </div>
      <Footer />
    </div>
  )
}

export default Privacy;