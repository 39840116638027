import React from 'react';

import MinNav from '../components/MinNav';
import Footer from '../components/Footer';
import TutorialMain from '../components/TutorialMain';

const Tutorial = () => {
  return (
    <div className="min-h-screen">
      <div className="gradient-bg-welcome">
        <MinNav />
        <TutorialMain />
      </div>
      <Footer />
    </div>
  )
}

export default Tutorial
