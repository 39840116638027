import React from 'react';

import Navbar from './components/Navbar';
import Welcome from './components/Welcome';
import Footer from './components/Footer';
import Services from './components/Services';
import Tutorial from './components/Tutorial';
import Launchpad from './components/Launchpad';

const App = () => {
  return (
    <div className="min-h-screen">
      <div className="gradient-bg-welcome">
        <Navbar />
        <Welcome />
      </div>
      <Services />
      <Tutorial />
      <Launchpad />
      <Footer />
    </div>
  )
}

export default App
