import uniswap from '../images/uniswap-v3.png';
import sushiswap from '../images/sushiswap-swipechain.png';
import oneinch from '../images/1inch-swipechain.png';
import aave from '../images/AAVE-swipechain.png';
import kyberswap from '../images/kyberswap-swipechain.png'; 

const ServiceCard = ({ color, title, img, subtitle }) => (
  <div className="flex flex-row justify-start items-center white-glassmorphism p-3 m-2 cursor-pointer hover:shadow-xl">
    <div className={`w-10 h-10 rounded-full flex justify-center items-center ${color}`}>
      {img}
    </div>
    <div className="ml-5 flex flex-col flex-1">
      <h1 className="mt-2 text-white text-lg">{title}</h1>
      <p className="mt-2 text-white text-sm md:w-9/12">{subtitle}</p>
    </div>
  </div>
)

const Services = () => {
  return (
    <div className="flex flex-col md:flex-row w-full justify-center items-center gradient-bg-services">
      <div className="flex mf:flex-row flex-col items-center justify-between md:p-20 py-12 px-4">
        <div className="flex-1 flex flex-col justify-start items-start">
          <h1 className="text-white text-3xl sm:text-5xl py-2 text-gradient">
            Platform
            <br/>
             Integrations
          </h1>
        </div>
      </div>
      <div className="flex-1 flex flex-col justify-start items-center">
        <ServiceCard 
          color="bg-[#2952E3]"
          title="Uniswap"
          img={<img src={uniswap} alt="Uniswap" className="w-32 cursor-pointer"/>}
          subtitle="Add & Remove Liquidity, Trade & Swap ERC tokens across the blockchain "
        />    
        <ServiceCard 
          color="bg-[#8945F8]"
          title="Sushiswap"
          img={<img src={sushiswap} alt="Sushiswap" className="w-32 cursor-pointer"/>}
          subtitle="Staking tokens, Claiming & swapping tokens with sushiswap"
        />    
        <ServiceCard 
          color="bg-[#F84550]"
          title="1inch"
          img={<img src={oneinch} alt="1inch" className="w-32 cursor-pointer"/>}
          subtitle="Perform multi path token-Swaps across different decentralized exchanges(DEXs)"
        />    
        <ServiceCard 
          color="bg-[#2952E3]"
          title="AAVE"
          img={<img src={aave} alt="AAVE" className="w-32 cursor-pointer"/>}
          subtitle="Depositing, Borrowing, Staking & Flashloans"
        />
        <ServiceCard 
          color="bg-[#F84550]"
          title="Kyberswap"
          img={<img src={kyberswap} alt="Kyberswap" className="w-32 cursor-pointer"/>}
          subtitle="Yield farming: Bridge assets, Swap & Earn Tokens at best rates"
        /> 
      </div>
    </div>
  );
}

export default Services;
