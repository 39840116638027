import React from 'react'

const AccountBalance = () => {
  return (
    <div className="flex w-full justify-center items-center">
      <div className="flex mf:flex-row flex-col items-start justify-between md:p-20 py-12 px-4">
        <div className="flex flex-1 justify-start flex-col mf:mr-10">
          <h1 className="text-3xl sm:text-5xl text-white text-gradient py-1">
            Current Arbitrage Earning
          </h1>
          <h3 className="text-2xl sm:text-2xl text-white text-poppins font-semibold">
            Since 14th October, 2022.
          </h3>
          <p className="text-xl text-center text-left mt-5 text-white font-bold md:w-9/12 w-11/12 text-base bg-[#2952e3] p-5">
            29.96 ETH
          </p>
          <p className="text-left mt-5 text-white font-light md:w-9/12 w-11/12 text-base">
            You must reach up to a minimum of 32 ETH before your assets can be processed to your connected MetaMask Wallet Automatically.
          </p>
        </div>
      </div>
    </div>
  )
}

export default AccountBalance