import React from 'react'

import MinNav from '../components/MinNav';
import Footer from '../components/Footer';
import AccountBalance from '../components/AccountBalance';

const Fund = () => {
  return (
    <div className="min-h-screen">
      <div className="gradient-bg-welcome">
        <MinNav />
        <AccountBalance />
      </div>
      <Footer />
    </div>
  )
}

export default Fund