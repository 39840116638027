import { useState, useContext } from 'react';
import { HiMenuAlt4 } from 'react-icons/hi';
import { AiOutlineClose } from 'react-icons/ai';

import { Link } from 'react-router-dom';

import logo from '../images/swipechain.png';

import { TransactionContext } from '../context/TransactionContext';

const NavbarItem = ({ title, classProps }) => {
  return (    
    <li className={`mx-4 cursor-pointer ${classProps}`}>
      {title}
    </li>
  )
}

const Navbar = () => {
  const { connectWallet, currentAccount } = useContext(TransactionContext);

  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <nav className="w-full flex md:justify-center justify-between items-center p-4">
      <div className="md:flex-[0.5] flex-initial justify-center items-center">
        <Link to='/'>
          <img src={logo} alt="Logo" className="w-32 cursor-pointer"/>
        </Link>
      </div>
      <ul className="text-white md:flex hidden list-none flex-row justify-between items-center flex-initial ">
        <li className={'mx-4 cursor-pointer'}>
          <Link to='/' className="!text-white">Home</Link>
        </li>
        <li className={'mx-4 cursor-pointer'}>
          <Link to='/market' className="!text-white">Market</Link>
        </li>
        <li className='mx-4 cursor-pointer'>
          <Link to='/tutorial' className="!text-white">Tutorial</Link>
        </li>
        <li className='mx-4 cursor-pointer'>
          <Link to='wallet' className="!text-white">Wallet</Link>
        </li>
        <li className='mx-4 cursor-pointer'>
          <Link to='account' className="!text-white">My Account</Link>
        </li>
      </ul>
      <div className="flex relative">
          {toggleMenu 
            ? <AiOutlineClose fontSize={28} className="text-white md:hidden cursor-pointer" onClick={() => setToggleMenu(false)}/>
            : <HiMenuAlt4 fontSize={28} className="text-white md:hidden cursor-pointer" onClick={() => setToggleMenu(true)}/>}
          {toggleMenu && (
            <ul
              className="z-10 fixed top-0 -right-2 p-3 w-[70vw] h-screen shadow-2xl md:hidden list-none flex flex-col justify-start items-end rounded-md blue-glassmorphism !text-white animate-slide-in"
            >
              <li className="size= w-full my-2">
                <AiOutlineClose onClick={() => setToggleMenu(false)}/>
              </li>
              <li className={'mx-4 cursor-pointer'}>
                <Link to='/' className="!text-white">Home</Link>
              </li>
              <li className={'mx-4 cursor-pointer'}>
                <Link to='/market' className="!text-white">Market</Link>
              </li>
              <li className='mx-4 cursor-pointer'>
                <Link to='/tutorial' className="!text-white">Tutorial</Link>
              </li>
              <li className='mx-4 cursor-pointer'>
                <Link to='wallet' className="!text-white">Wallet</Link>
              </li>
              <li className='mx-4 cursor-pointer'>
                <Link to='account' className="!text-white">My Account</Link>
              </li>
            </ul>
          )}
      </div>
    </nav>
  );
};

export default Navbar;
