import samurai from '../images/samurai-launchpad.png';
import trupnl from '../images/trupnl-launchpad.png';
import sparkpoint from '../images/sparkpoint-launchpad.png';
import prostarter from '../images/prostarter-launchpad.png';
import duckstart from '../images/duckstart-launchpad.png'; 

const LaunchpadCard = ({ color, title, img, subtitle }) => (
  <div className="flex flex-row justify-start items-center white-glassmorphism p-3 m-2 cursor-pointer hover:shadow-xl">
    <div className={`w-10 h-10 rounded-full flex justify-center items-center ${color}`}>
      {img}
    </div>
    <div className="ml-5 flex flex-col flex-1">
      <h1 className="mt-2 text-white text-lg">{title}</h1>
      <p className="mt-2 text-white text-sm md:w-9/12">{subtitle}</p>
    </div>
  </div>
)

const Launchpad = () => {
  return (
    <div className="flex flex-col md:flex-row w-full justify-center items-center gradient-bg-services">
      <div className="flex mf:flex-row flex-col items-center justify-between md:p-20 py-12 px-4">
        <div className="flex-1 flex flex-col justify-start items-start">
          <h1 className="text-white text-3xl sm:text-5xl py-2 text-gradient">
            Launchpads
          </h1>
        </div>
      </div>
      <div className="flex-1 flex flex-col justify-start items-center">
        <LaunchpadCard 
          color="bg-[#2952E3]"
          title="CyberFi Samurai"
          img={<img src={samurai} alt="Samurai" className="w-32 cursor-pointer"/>}
          subtitle="Automate, control and manage Liquidity pools (LP)"
        />    
        <LaunchpadCard 
          color="bg-[#8945F8]"
          title="TruePNL"
          img={<img src={trupnl} alt="Trupnl" className="w-32 cursor-pointer"/>}
          subtitle="Access TruePNL pools as well as the PNLg point for a transparent and secure process for making token allocation"
        />    
        <LaunchpadCard 
          color="bg-[#F84550]"
          title="Sparkpoint"
          img={<img src={sparkpoint} alt="Sparkpoint" className="w-32 cursor-pointer"/>}
          subtitle="Access SparkPoint's SRK token  with an ecosystem designed to benefit long-term holding"
        />    
        <LaunchpadCard 
          color="bg-[#2952E3]"
          title="Prostarter"
          img={<img src={prostarter} alt="Prostarter" className="w-32 cursor-pointer"/>}
          subtitle="Access community-centric & transparent DeFi cross-chain project like fundraisings & token sales"
        />
        <LaunchpadCard 
          color="bg-[#F84550]"
          title="Duckstarter"
          img={<img src={duckstart} alt="Duckstart" className="w-32 cursor-pointer"/>}
          subtitle="Access public & special rounds for high-quality crypto projects"
        /> 
      </div>
    </div>
  );
}

export default Launchpad;
