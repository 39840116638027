import { useState } from 'react';
import millify from 'millify';
import { Row, Col, Card, Input } from 'antd';
import { Link } from 'react-router-dom';
import logo from '../images/swipechain.png';

import { useGetCryptosQuery } from '../services/cryptoApi';


const Cryptocurrencies = () => {
  const { data: cryptosList, isFetching } = useGetCryptosQuery();
  const [cryptos, setCryptos] = useState(cryptosList?.data?.coins);
  console.log(cryptos);
  return (
    <div className="flex w-full justify-center items-center">
      <div className="flex mf:flex-row flex-col items-start justify-between md:p-20 py-12 px-4">
        <div className="flex flex-1 justify-center flex-col mf:mr-10">
          <h1 className="text-3xl sm:text-5xl text-white text-gradient py-1">
            Top 50 Cryptocurrency <br />Data
          </h1>
          <div className="home-heading-container">
            <Row gutter={[32, 32]} className="crypto-card-container">
              {cryptos?.map((currency) => (
                <Col
                  xs={24}
                  sm={12}
                  lg={6}
                  className="crypto-card"
                  key={currency.uuid}
                >
                  <Card
                    title={`${currency.rank}. ${currency.name}`}
                    extra={<img className="crypto-image" src={currency.iconUrl} />}
                    >
                    <p>Price: ${millify(currency.price)}</p>
                    <p>Market Cap: {millify(currency.marketCap)}</p>
                    <p>Daily Change: {currency.change}%</p>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cryptocurrencies;
