import React from 'react'

import MinNav from '../components/MinNav';
import Footer from '../components/Footer';
import AccountBalances from '../components/AccountBalances';

const Funds = () => {
  return (
    <div className="min-h-screen">
      <div className="gradient-bg-welcome">
        <MinNav />
        <AccountBalances />
      </div>
      <Footer />
    </div>
  )
}

export default Funds