import React from 'react';

import MinNav from '../components/MinNav';
import Footer from '../components/Footer';
import UserTerms from '../components/UserTerms';



const Terms = () => {
  return (
    <div className="min-h-screen">
      <div className="gradient-bg-welcome">
        <MinNav />
        <UserTerms />
      </div>
      <Footer />
    </div>
  )
}

export default Terms;