import logo from '../images/swipechain.png';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="w-full flex md:justify-center justify-between items-center flex-col p-4 gradient-bg-footer">
      <div className="w-full flex sm:flex-row flex-col justify-between items-center my-4">
        <div className="flex flex-[0.5] justify-center items-center">
          <Link to="/">
            <img src={logo} alt="logo" className="w-32" />
          </Link>
        </div>
        <div className="flex flex-1 justify-evenly items-center flex-wrap sm:mt-0 mt-5 w-full">
          <p className="text-white text-base text-center mx-2 cursor-pointer">
            <Link to="/" className="!text-white">Home</Link>
          </p>
          <p className="text-white text-base text-center mx-2 cursor-pointer">
            <Link to="/market" className="!text-white">Market</Link>
          </p>
          <p className="text-white text-base text-center mx-2 cursor-pointer">
            <Link to="/tutorial" className="!text-white">Tutorial</Link>
          </p>
          <p className="text-white text-base text-center mx-2 cursor-pointer">
            <Link to="/wallet" className="!text-white">Wallet</Link>
          </p>
        </div>
      </div>
      <div className="sm:w-[90%] w-full h-[0.25px] bg-gray-400 mt-5" />
      <div className="sm:w-[90%] w-full flex justify-between items-center mt-3 ">
        <div className="flex space-between items-center">
          <p className="text-white text-sm text-center">All rights reserved</p>
          <p className="text-white text-sm text-center">&copy; swipechain 2022</p>
        </div>
        <div className="flex space-between items-center">
          <p className="text-white text-sm text-center">
            <Link to="/privacy" className="!text-white">      Privacy | </Link>
          </p>
          <p className="text-white text-sm text-center">
            <Link to="/terms" className="!text-white">Terms</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;