import React from 'react';
import VideoPlayer from 'react-video-js-player';
import tut from '../images/swipechain-guide.mp4';

const Tutorial = () => {
  return (
    <>
      <div className="flex flex-col md:flex-row w-full justify-center items-center gradient-bg-services">
        <div className="flex mf:flex-row flex-col items-center justify-between md:p-20 py-12 px-4">
          <VideoPlayer src={tut} width="325" height="240"/>
        </div>
        <div className="flex-1 flex flex-col justify-start items-center">
          <div className="flex-1 flex flex-col justify-start items-start">
            <h1 className="text-white text-3xl sm:text-5xl py-2 text-gradient">
              Learn 
              <br/>
              how-to-use
            </h1>
          </div>
        </div>
      </div>
    </>
  )
}

export default Tutorial
