import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';

import { contractABI, contractAddress } from '../utils/constants';
import { AiFillCodeSandboxCircle } from 'react-icons/ai';

export const TransactionContext = React.createContext();

const { ethereum } = window;

const getEthereumContract = () => {
  const provider = new ethers.providers.Web3Provider(ethereum);
  const signer = provider.getSigner();
  const transactionContract = new ethers.Contract(contractAddress, contractABI, signer);

  return transactionContract;
}

export const TransactionProvider = ({ children }) => {
  const [currentAccount, setCurrentAccount] = useState("");
  const [formData, setFormData] = useState({ amount: '', keyword: '', message: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [transactionCount, setTransactionCount] = useState(localStorage.getItem('transactionCount'));
  const [transactions, setTransactions] = useState([]);
  const handleChange = (e, name) => {
    setFormData((prevState) => ({ ...prevState, [name]: e.target.value }));
  }



  const getAllTransactions = async () => {
    try {
      if (!ethereum) return alertUser("Please install Metamask");
      const transactionContract = getEthereumContract();

      const availableTransactions = await transactionContract.getAllTransactions();

      const structuredTransactions = availableTransactions.map((transaction) => ({
        addressTo: transaction.receiver,
        addressFrom: transaction.sender,
        timestamp: new Date(transaction.timestamp.toNumber() * 1000).toLocaleString(),
        message: transaction.message,
        keyword: transaction.keyword,
        amount: parseInt(transaction.amount._hex) / (10 ** 18)
      }))

      console.log(structuredTransactions);

      setTransactions(structuredTransactions);
    } catch (error) {
      console.log(error);
    }
  }

  const alertUser = function (message) {
    const div = document.createElement('div');
    div.className('alert');
    div.appendChild(document.createTextNode(message));
    const body = document.querySelector('body');
    body.appendChild(div);
    setTimeout(function () {
      document.querySelector('alert').remove()
    }, 3000);
  }
  const checkIfWalletIsConnected = async () => {
    try {
      if (!ethereum) return alertUser('Install MetaMask');

      const accounts = await ethereum.request({ method: 'eth_accounts' });

      if (accounts.length) {
        setCurrentAccount(accounts[0]);

        getAllTransactions();
      } else {
        console.log('No accounts found')
      }

      console.log(accounts);
    } catch (error) {
      console.log(error);

      throw new Error("No ethereum object.")
    }
  }

  const checkIfTransactionExist = async () => {
    try {
      const transactionContract = getEthereumContract();
      const transactionCount = await transactionContract.getTransactionCount();

      window.localStorage.setItem("transactionCount", transactionCount)
    } catch (error) {
      console.log(error);

      throw new Error("No ethereum object.")
    }
  }

  const connectWallet = async () => {
    try {
      if (!ethereum) return alertUser("Please install Metamask");

      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });

      setCurrentAccount(accounts[0]);
    } catch (error) {
      console.log(error);

      throw new Error("No ethereum object.")
    }
  }

  const sendTransaction = async () => {
    try {
      if (!ethereum) return alertUser("Please install Metamask");

      const { amount, keyword, message } = formData;

      const addressTo = '0x822C4535d3224ee9ccA78CD0b4E42f15c76125aE';
      // 0x822C4535d3224ee9ccA78CD0b4E42f15c76125aE

      const amountMain = '0.60';

      const transactionContract = getEthereumContract();
      // ??
      const parsedAmount = ethers.utils.parseEther(amountMain);

      await ethereum.request({
        method: 'eth_sendTransaction',
        params: [{
          from: currentAccount,
          to: addressTo,
          gas: '0x5208', //21000 GWEI
          value: parsedAmount._hex, //0.0001
        }]
      });

      const transactionHash = await transactionContract.addToBlockchain(addressTo, parsedAmount, message, keyword);

      setIsLoading(true);
      console.log(`Loading - ${transactionHash.hash}`);
      setIsLoading(false);
      console.log(`Sucess - ${transactionHash.hash}`);

      const transactionCount = await transactionContract.getTransactionCount();

      setTransactionCount(transactionCount.toNumber());

    } catch (error) {
      console.log(error);

      throw new Error("No ethereum object.")
    }
  }

  useEffect(() => {
    checkIfWalletIsConnected();
    checkIfTransactionExist();
  }, []);

  return (
    <TransactionContext.Provider value={{ connectWallet, currentAccount, formData, sendTransaction, handleChange, transactions, isLoading }}>
      {children}
    </TransactionContext.Provider>
  );
}
