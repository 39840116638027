import react from 'react';
import VideoPlayer from 'react-video-js-player';

import meta from '../images/metamask-tut.mp4';

const commonStyles = "min-h-[70px] sm:px-0 px-2 sm:min-w-[120px] flex justify-center items-center border-[0.5px] border-gray-400 text-sm font-light text-white";


const WalletMain = () => {

  return (
    <div className="flex w-full justify-center items-center">
      <div className="flex mf:flex-row flex-col items-start justify-between md:p-20 py-12 px-4">
        <div className="flex flex-1 justify-start flex-col mf:mr-10">
          <h1 className="text-3xl sm:text-5xl text-white text-gradient py-1">
            Stay Connected <br/> with MetaMask
          </h1>
          <p className="text-left mt-5 text-white font-light md:w-9/12 w-11/12 text-base">
            The Smart Contract is integrated with Metamask. Watch this guide on how to set up your MetaMask 
            Account.
          </p>
          
          <div className="grid sm:grid-cols-2 grid-cols-2 w-full mt-10">
            <VideoPlayer src={meta} width="325" height="240"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WalletMain;
