import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// import styles from '../style';

const InputForm = () => {
  const [securityCode, setSecurityCode] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    const data = { securityCode };

    if (data.securityCode === '0x0C10pepE9e5c6830KDBC70cH44593Ne093F57fd1') {
      navigate('/fund');
    } else if (data.securityCode === 
    '0x89V786NBuH44593Ne093F57fd1BN0C10p9e5c68K') {
      navigate('/funds');
    }

    e.preventDefault();
  }

  return (
    <div className="flex w-full justify-center items-center">
      <div className="flex mf:flex-row flex-col items-start justify-between md:p-20 py-12 px-4">
        <div className="flex flex-1 justify-start flex-col mf:mr-10">
          <h1 className="text-3xl sm:text-5xl text-white text-gradient py-1">
            Input Contract Address
          </h1>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              className="w-[350px] py-4 px-6 rounded-xl text-center font-poppins font-semibold text-[20px] bg-white sm:w-[750px] mr-3"
              value={securityCode}
              placeholder="Input Here.."
              onChange={(e) => setSecurityCode(e.target.value)}
            />
            <button className="py-4 px-6 bg-[#2952e3] font-poppins font-medium text-[18px] text-white outline-none mt-5 rounded-[10px]">Access</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default InputForm