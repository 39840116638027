import React from 'react';

import MinNav from '../components/MinNav';
import Footer from '../components/Footer';
import WalletMain from '../components/WalletMain';

const Wallet = () => {
  return (
    <div className="min-h-screen">
      <div className="gradient-bg-welcome">
        <MinNav />
        <WalletMain />
      </div>
      <Footer />
    </div>
  )
}

export default Wallet
