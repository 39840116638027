import react from 'react';
import VideoPlayer from 'react-video-js-player';

import tut from '../images/swipechain-guide.mp4';


const InnerWelcome = () => {

  return (
    <div className="flex w-full justify-center items-center">
      <div className="flex mf:flex-row flex-col items-start justify-between md:p-20 py-12 px-4">
        <div className="flex flex-1 justify-start flex-col mf:mr-10">
          <h1 className="text-3xl sm:text-5xl text-white text-gradient py-1">
            Learn How-to-use <br/> this powerful system
          </h1>
          <p className="text-left mt-5 text-white font-light md:w-9/12 w-11/12 text-base">
            Watch this guide and understand how the smart contract is carried out.
          </p>
          
          <div className="grid sm:grid-cols-2 grid-cols-2 w-full mt-10">
            <VideoPlayer src={tut} width="325" height="240"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InnerWelcome;
