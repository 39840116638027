import React from 'react';
import { render } from 'react-dom';

import { Provider } from 'react-redux';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Market from './Pages/Market';
import Wallet from './Pages/Wallet';
import Tutorial from './Pages/Tutorial';
import Terms from './Pages/Terms';
import Privacy from './Pages/Privacy';
import Account from './Pages/Account';
import Fund from './Pages/Fund';
import Funds from './Pages/Funds';


import './index.css';
import App from './App';
import store from './app/store';

import 'antd/dist/antd.css';

import { TransactionProvider } from './context/TransactionContext';

const rootElement = document.getElementById("root");

render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={
        <TransactionProvider>
          <React.StrictMode>
            <Provider store={store}>
              <App />
            </Provider>
          </React.StrictMode>
        </TransactionProvider>
      } />
      <Route path="/market" element={
        <Provider store={store}>
          <Market />
        </Provider>
      } />
      <Route path="/tutorial" element={<Tutorial />} />
      <Route path="/wallet" element={<Wallet />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/account" element={<Account />} />
      <Route path="/fund" element={<Fund />} />
      <Route path="/funds" element={<Funds />} />
    </Routes>
  </BrowserRouter>,
  rootElement
)
