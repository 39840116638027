import React from 'react'

import MinNav from '../components/MinNav';
import Footer from '../components/Footer';
import InputForm from '../components/InputForm';

const Account = () => {
  return (
    <div className="h-48">
      <div className="gradient-bg-welcome">
        <MinNav />
        <InputForm />
      </div>
      <Footer />
    </div>
  )
}

export default Account