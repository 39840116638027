import React from 'react'

const UserPrivacy = () => {
  return (
    <div className="w-full text-white items-center">
      <h2 className="text-white mt-10 text-xl text-poppins">Privacy & Policy</h2>
      <h3 className="text-white mt-2 text-l font-bold">SwipeChain Privacy Notice</h3>
      <p className="mb-4">We greatly appreciate the trust that you have placed in us and will do our best to protect your personal data.</p>
      <p className="mb-4">Personal data is any information that relates to an identifiable natural person and includes name, address, e-mail address and user behaviour. The collection, use and disclosure (“processing”) of your personal data by us or any third-party recipient at all times will be in accordance with the terms of this Privacy Note.</p>
      <p className="mb-4 font-bold">1. Our commitment to data protection principles</p>
      <p className="mb-4 font-bold">We are responsible for the processing of your personal data (as a “data controller” pursuant to the GDPR / FADP). In order to protect your personal data in connection with the usage of the website, we are committed to adhere to the GDPR / FADP principles below.</p>
      <p className="mb-4">Every individual working for us must also adhere to these principles in performing his or her day-to-day duties. We will therefore make sure that your personal data is:</p>
      <p className="mb-4">i. Processed lawfully, fairly and in a transparent manner (lawfulness, fairness and transparency);</p>
      <p className="mb-4">ii. Collected for specified, explicit and legitimate purposes and not further processed in a manner that is incompatible with those purposes (purpose limitation);</p>
      <p className="mb-4">Adequate, relevant and limited to what is necessary in relation to the purposes for which it is processed (data minimisation);</p>
      <p className="mb-4">Accurate and, where necessary, kept up to date; every reasonable step will be taken to ensure that your personal data that is inaccurate, having regard to the purposes for which it is processed, is erased or rectified without delay (accuracy);</p>
      <p className="mb-4">Kept in a form which permits your identification for no longer than is necessary for the purposes for which personal data is processed (storage limitation);</p>
      <p className="mb-4">Processed in a manner that ensures appropriate security of your personal data, including protection against unauthorised or unlawful processing and against accidental loss, destruction or damage using appropriate technical or organisational measures (integrity and confidentiality).</p>
      <p className="mb-4">The controller of your personal data is the Tezos Foundation. If you have any question, you can reach out to support@swipechain.app</p>
      <p className="font-bold mb-4">2. Disclosure and transfer of your personal data</p>
      <p className="mb-4">We will not share any of your personal data with any government authorities, except in order to comply with legal or regulatory requirements. If we receive a request to disclose your personal data to a government authority, we will thoroughly assess the request and will in particular consider possible legal challenges against such request. We will only comply with any such request that is binding, enforceable and issued in full compliance with applicable law.</p>
      <p className="mb-4">We disclose your personal data to IT and other third-party service providers in Switzerland, the EU and the USA, which are under strict confidentiality obligations and are obliged to protect data privacy to the same extent as we ourselves.</p>
      <p className="mb-4 font-bold">3. Collecting of personal data when visiting our website</p>
      <p className="mb-4">If you merely use this website for informational purposes, we will only collect your personal data that is transferred by your browser to our server. We collect online properties, identification data, professional life data, personal life data, connection data and localization data (including IP addresses), which is technically necessary for us to show you our website and ensure security and stability. The data collected is not linked to or stored with any personal data.</p>
      <p className="mb-4">We evaluate the IP addresses together with other data, in case of attacks on our infrastructure or where unauthorized or abusive use of our website is suspected, for the purpose of intelligence and protection, and if appropriate, used in criminal proceedings for identification and civil and criminal proceedings against the relevant users. This is our legitimate interest in the processing of data in the sense of the relevant laws.</p>
      <p className="mb-4">Our website provider is Blokhaus Inc, who uses Amazon Web Services as a hosting provider, Cloudflare as content distribution network and Google Inc. as analytics provider. The transfer of data is for the purpose of providing and maintaining the functionality of our website.</p>
      <p className="mb-4 font-bold">4. Collecting of personal data when signing up for the Newsletter</p>
      <p className="mb-4">When signing up for the Newsletter on our website, you need to enter your email address.</p>
      <p className="mb-4">You must enter the email address voluntarily, in order for us to be able to contact. Therefore, the processing of this data is in our legitimate interest in accordance in the sense of the relevant laws.</p>
      <p className="mb-4 font-bold">5. Use of Google Analytics</p>
      <p className="mb-4">
        We use Google Analytics, a web analysis service of Google Inc., 1600 Amphitheater Parkway, Mountain View, CA 94043, USA (“Google”). Google Analytics uses cookies, i.e. text files, which are stored on your computer and allow Google to analyse the use of our website. The information recorded by the cookie on the use of this website (including your IP address) is usually transferred to a server of Google in the USA and stored there.
      </p>
      <p className="mb-4">Google Analytics is extended on our web pages with the code “gat._anonymizeIp();;” to ensure that IP addresses are collected anonymously (IP masking). Your IP address is only recorded by Google in abbreviated form, which ensures anonymity and prevents any conclusions being drawn about your identity. In the event of the activation of the IP anonymisation on this website, your IP address is first shortened by Google within Member States of the European Union or in other states that are party to the Agreement on the European Economic Area. Only in exceptional cases will the full IP address be transmitted to a Google server in the USA and shortened there. On behalf of the operator of this website, Google will use this information to evaluate your use of our website, to compile reports on the website activities and to perform further services associated with the use of website and the internet for us. The IP address transmitted by your browser as part of Google Analytics will not be compiled with any other data held by Google. Google transfers this data to third parties only due to legal regulations, or in the context of order data processing. By using this website, you agree to the processing of data collected about you by Google, and in the manner described and for the aforementioned purpose. You can prevent the storage of cookies by selecting the corresponding setting in your browser software. However, please note that in this case you may not be able to use all the functions on our website. Furthermore, you can prevent Google’s collection and use of data generated by the cookie and related to your use of the website (including your IP address) by downloading and installing the browser plug-in available at the following link. For more information about Google Analytics and privacy, <a href="https://tools.google.com/dlpage/gaoptout?hl=en" target="_blank">https://tools.google.com/dlpage/gaoptout?hl=en</a></p>
      <p className="font-bold mb-4">6. Advertising and Analytics</p>
      <p className="mb-4">We allow others to provide analytics services and serve advertisements on our behalf across the web and in mobile apps. These entities may use cookies, web beacons, device identifiers, and other technologies to collect information about your use of our Services and applications, including your IP address, web browser, mobile network information, pages viewed, time spent on pages or in web apps, links clicked, and conversion information. This information may be used by the Company and others to, among other things, analyze and store data, determine the popularity of certain content, deliver advertising and content targeted to your interests on our Services and other websites, and better understand your online activity</p>
      <p className="mb-4 font-bold">7. Securing your data</p>
      <p className="mb-4">We have implemented various technical and organizational security measures to ensure that your personal data is adequately protected from unauthorized access or other unlawful use. We use CloudFlare Inc. to secure and ensure reliability of our website.</p>
      <p className="mb-4">We have contractual arrangements with all of our IT and other third party service providers in place, which require them to also implement the necessary security measures.</p>
      <p className="mb-4">Our commitment to security also means that we have imposed strict confidentiality obligations on all of our staff and that we have implemented various data security policies to protect your personal data.</p>
      <p className="mb-4 font-bold">8. Your Choices</p>
      <p className="mb-4">Cookies: Most web browsers are set to accept cookies by default. If you prefer, you can usually adjust your browser settings to remove or reject browser cookies. Please note that removing or rejecting cookies could affect the availability and functionality of our Services. Communications Preferences: You may opt out of receiving promotional emails from the Company by following the instructions in those communications or by emailing support@swipechain.app . If you opt out, we may still send you non-promotional emails, such as those about our ongoing business relations.</p>
      <p className="mb-4 font-bold">9. Data retention</p>
      <p className="mb-4">Your personal data collected is deleted or blocked as soon as the purpose for the saving no longer applies. Further, the personal data can be saved, if this is intended by an act, law or other provisions, which the responsible person is subject to.</p>
      <p className="mb-4 font-bold">8. Your rights in connection with your data</p>
      <p className="mb-4">You are entitled to the following under the conditions set out in applicable law:</p>
      <p className="mb-4">i. To request access to your personal data, including to obtain a copy of such data;</p>
      <p className="mb-4">ii. To request correction of inaccurate personal data or to have incomplete personal data completed;</p>
      <p className="mb-4">iii. To request deletion of your personal data in certain circumstances, such as if the data has been processed in non-compliance with applicable requirements;</p>
      <p className="mb-4">iv. To request us to restrict the processing of your personal data;</p>
      <p className="mb-4">v. To object for legitimate reasons to the processing of your personal data when we process it on the basis of a legitimate interest;</p>
      <p className="mb-4">vi. To revoke any consent previously granted (if applicable) for the processing, but this will not affect the lawfulness of the processing until the revocation;</p>
      <p className="mb-4">vii. To request data portability where the data is processed on the basis of your consent or the necessity for the performance of a contract concluded with you;</p>
      <p className="mb-4">To lodge a complaint with the competent authority.</p>
      <p className="mb-4 font-bold">9. Contact</p>
      <p className="mb-4">If you would like to exercise your rights listed under point 8 above or if you have any request regarding our use of your personal data you may contact us by emailing <a href="mailto:support@swipechain.app" target="_blank">support@swipechain.app</a></p>
    </div>
  )
}

export default UserPrivacy
